import * as React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { StaticQuery, graphql } from "gatsby"

import SEO from "../components/SEO"
import Header from "../components/Header/Header"
import HeroBanner from "../components/HeroBanner"
import About from "../components/About"
import Testimonials from "../components/Testimonials"
import Footer from "../components/Footer/Footer"
import ScrollUp from "../components/ScrollUp"

export default function HomePage() {
  const intl = useIntl()
  return (
    <StaticQuery
      query={graphql`
        query HomeQuery {
          allSanityHome {
            nodes {
              Language
              subtitle
              maintitle
              desc {
                children {
                  text
                }
              }
              heroImage {
                asset {
                  fluid {
                    src
                  }
                }
                alt
                caption
              }
              testimonials {
                content
                name
                company
              }
              about_layout {
                features_list
                position
                subtitle
                maintitle
                feature_desc
                feature_image {
                  asset {
                    fluid {
                      src
                    }
                  }
                  alt
                  caption
                }
              }
              title
            }
          }
        }
      `}
      render={data => {
        let resource = null
        if (intl.locale === "en") {
          resource = data.allSanityHome.nodes.filter(
            item => item.Language === "en"
          )
          resource = resource[0]
          console.log(resource)
        } else {
          resource = data.allSanityHome.nodes.filter(
            item => item.Language === "fi"
          )
          resource = resource[0]
          console.log(resource)
        }
        return (
          <>
            {resource && (
              <>
                <SEO />
                <div className="wrapper">
                  <Header link="/" />
                  <HeroBanner
                    background={resource.heroImage}
                    desc={resource.desc}
                    title={resource.maintitle}
                    subtitle={resource.subtitle}
                  />

                  <About content={resource.about_layout} />

                  <Testimonials
                    content={resource.testimonials}
                    title={resource.title}
                  />
                  <Footer />
                  <ScrollUp />
                </div>
              </>
            )}
          </>
        )
      }}
    />
  )
}
