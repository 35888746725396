import React from "react"

export default function HeroBanner({ background, desc, title, subtitle }) {
  return (
    <section
      style={{
        backgroundImage: `url(${background.asset.fluid.src})`,
      }}
      className="features-carousel pb-70 pt-100 bg-dark container"
    >
      <div className="features-box">
        <h1 className="pagetitle__heading">{title}</h1>
        <h2>{subtitle}</h2>
        <p className="pagetitle__desc">{desc[0].children[0].text}</p>
      </div>
    </section>
  )
}
