import React from "react"

export default function About({ content }) {
  return (
    <>
      {content.map((item, index) => {
        if (item.position === "left") {
          return (
            <section key={index} className="about-layout2 pb-0 pt-0 mb-80">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="heading-layout">
                      <h3 className="heading__title">{item.maintitle}</h3>
                      <p className="heading__desc mb-30">{item.subtitle}</p>
                      <p>
                        <strong>{item.feature_desc}</strong>
                      </p>
                      <ul className="list-items list-unstyled mt-40">
                        {item.features_list.map((text, index) => {
                          return <li key={index}>{text}</li>
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="about__img">
                      <img
                        src={item.feature_image.asset.fluid.src}
                        alt="about"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )
        } else {
          return (
            <section key={index} className="about-layout">
              <div className="container">
                <div className="row column-reverse">
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="about__img">
                      <img
                        src={item.feature_image.asset.fluid.src}
                        alt="about"
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="heading-layout mb-30">
                      <h2 className="heading__title mb-30">{item.maintitle}</h2>
                      <p className="heading__desc mb-25">{item.subtitle}</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )
        }
      })}
    </>
  )
}
