import React from "react"
import thumb1 from "../assets/images/testimonials/thumbs/1.jpg"
import thumb2 from "../assets/images/testimonials/thumbs/2.jpg"
import thumb3 from "../assets/images/testimonials/thumbs/3.jpg"
import thumb4 from "../assets/images/testimonials/thumbs/4.jpg"

export default function Testimonial({ content, title }) {
  return (
    <section className="testimonials testimonials-layout1 text-center pt-0 pb-80">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="heading mb-30">
              <h2 className="heading__subtitle">{title}</h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-10 offset-lg-1">
            {content.map((item, index) => {
              return (
                <div>
                  <div key={index} className="testimonial-item">
                    <p className="testimonial-item__desc">{item.content}</p>
                    <div className="testimonial-item__meta">
                      <h4 className="testimonial-item__meta__title">
                        {item.name}
                      </h4>
                      <p className="testimonial-item__meta__desc">
                        {item.company}
                      </p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
